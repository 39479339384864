import React, { useRef, useState } from "react";
import styled from "styled-components";
import useAddQuery from "../../../helpers/useAddQuery";
import { Button, Form } from "../../../styles/styles";

const AddDef = () => {
  const Mutation = useAddQuery();

  const [newdef, setNewDef] = useState({
    title: "",
    snippet: "",
    definition: "",
    video_link: "",
    language: "",
  });
  const firstInputRef = useRef();

  return (
    <div className="SectionContainer">
      <AddDefinitionContainer>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            Mutation.mutate(newdef);
            setNewDef({
              title: "",
              snippet: "",
              definition: "",
              video_link: "",
              language: "",
            });
            firstInputRef.current.focus();
          }}
          flexDirection="column"
        >
          <label>Definition Title
            <input
              ref={firstInputRef}
              type="text"
              value={newdef.title}
              onChange={(e) => setNewDef({ ...newdef, title: e.target.value })}
              />
          </label>
          <label>Snippet
            <input
              id="snippet"
              type="text"
              value={newdef.snippet}
              onChange={(e) => setNewDef({ ...newdef, snippet: e.target.value })}
            />
          </label>
          <label>Language
            <input
              id="language"
              type="text"
              value={newdef.language}
              onChange={(e) => {
                setNewDef({ ...newdef, language: e.target.value });
              }}
            />
          </label>
          <label>Video Link
            <input
              id="video"
              type="text"
              value={newdef.video_link}
              placeholder="https://www.youtube.com/xxxxxxx"
              onChange={(e) =>
                setNewDef({ ...newdef, video_link: e.target.value })
              }
            />
          </label>
          <label>Definition
            <textarea
              type="text"
              value={newdef.definition}
              onChange={(e) =>
                setNewDef({ ...newdef, definition: e.target.value })
              }
            />
          </label>
          <Button type="submit">Submit</Button>
        </Form>
      </AddDefinitionContainer>
    </div>
  );
};

export default AddDef;

const AddDefinitionContainer = styled.div`
  margin: 100px auto;
  height: auto;
  ${Form} {
    width: clamp(300px, 50vw, 600px);
    textarea {
      width: clamp(320px, 50vw, 600px);
    }
  }
`;
