import { useMutation, useQueryClient } from "react-query";
import { baseURL } from "./baseUrl";

function useDeleteQuery() {
  const queryClient = useQueryClient();
  return useMutation(
    async (id) =>
      await fetch(`${baseURL}adminarea/delete/${id}`, {
        method: "DELETE",
      }).then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          throw new Error("error deleting data");
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("definitions"),
    }
  );
}

export default useDeleteQuery;
