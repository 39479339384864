import { memo, useState } from "react";
import Editdefinition from "../EditDefinition/Editdefinition";
import styled from "styled-components";
import { Button, Botonera } from "../../../styles/styles";
import useQueryAll from "../../../helpers/useQueryAll";
import useDeleteQuery from "../../../helpers/useDeleteQuery";

const Definitions = memo(() => {
  const [editDefinition, setEditDefinition] = useState({
    edit: false,
    id: null,
  });
  const { data, isLoading, isError } = useQueryAll();
  const Mutation = useDeleteQuery();

  if (isLoading) {
    return <p>Loading definitions...</p>;
  }

  if (isError) {
    return <p>Error loading definitions</p>;
  }

  return (
    <Container>
      {data &&
        data.length &&
        data.map((def) => {
          return (
            <OneDefinition key={def._id}>
              <div>
                <Title>TITLE: {def.title}</Title>
                <p>SNIPPET: {def.snippet}</p>
                <p className="one_definition">DEFINITION: {def.definition}</p>
                <p>LANGUAGE: {def.language}</p>
                <p>VIDEO_LINK: {def.video_link}</p>
              </div>
              <Botonera>
                <Button onClick={() => Mutation.mutate(def._id)}>Del</Button>
                <Button
                  onClick={() => setEditDefinition({ edit: true, id: def._id })}
                >
                  Edit
                </Button>
              </Botonera>
              {editDefinition.edit && editDefinition.id === def._id && (
                <Editdefinition
                  id={def._id}
                  setEditDefinition={setEditDefinition}
                />
              )}
            </OneDefinition>
          );
        })}
    </Container>
  );
});

export default Definitions;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 50px;
  margin: 100px auto;
  gap: 16px;
`;

const Title = styled.div`
  margin: 10px 0;
  line-height: 1.6;
  color: var(--black);
  font-weight: bolder;
  font-size: 1.6rem;
`;

const OneDefinition = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 600px;
  height: auto;
  padding: 20px;
  color: var(--black);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 250ms ease-in;
  .one_definition {
    white-space: pre-wrap;
    max-width: 600px;
  }
  :hover {
    transform: skew(-2deg);
  }
`;
