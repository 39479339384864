import useQueryOneById from "../../../helpers/useQueryOneById";
import FormEditDefinition from "./FormEditDefinition";

const Editdefinition = (props) => {
  const { data, isError, isLoading, isSuccess } = useQueryOneById(props.id);


  if (isLoading) {
    return <p>loading definition</p>;
  }

  if (isError) {
    return <p>there was an error fetching the definition</p>;
  }

  if (isSuccess) {
    return <FormEditDefinition data = {data} setEditDefinition = {props.setEditDefinition}/>
  }
};

export default Editdefinition;

