import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import useQueryOne from "../helpers/useQueryOne";
import { Table, Form, ViewButton, Button } from "../styles/styles";

const useViewSelector = () => {
  const [isCardView, setIsCardView] = useState(false)
  return [
    isCardView,
    <ViewButton onClick={() => setIsCardView(!isCardView)}>{isCardView ? 'List' : 'Card'}</ViewButton>,
  ]
}

const Search = () => {
  const inputRef = useRef();
  const [query, setQuery] = useState(null)
  const { data, isError, isLoading } = useQueryOne(
    query
  );
  const [isCardView, ViewSelector] = useViewSelector()

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    setQuery(formData.get('query'))
  }

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <section>
      <SearchContainer>
        <Header>
          <h1>
            Tango Definitions
            <Underline>.com</Underline>
          </h1>
          <h3>by Matias Facio</h3>
        </Header>
        <Form onSubmit={handleSubmit} flexDirection="row">
          <input
            ref={inputRef}
            type="text"
            name="query"
            placeholder="enter your query here"
          />
          <Button type="submit" isPrimaryColor={false}>Search</Button>
        </Form>
        {data && ViewSelector}
      </SearchContainer>

      {isLoading && (
        <p style={{ color: "whitesmoke", opacity: 0.5, textAlign: "center" }}>
          Loading definitions
        </p>
      )}
      {isError && (
        <p style={{ color: "red", textAlign: "center" }}>
          Sorry, there was an error. Please try again later!
        </p>
      )}
      {isCardView ? <Cards data={data} /> : <TableView data={data} />}
    </section>
  );
};

export default Search;

const Cards = ({ data }) => {
  if (!data) {
    return null
  }
  return <CardsContainer>{data.map((element, idx) => {
    return (
      <Card key={idx} >
        <p className="title">{element.title}</p>
        <p className="snippet">{element.snippet}</p>
        <p>{element.definition}</p>
      </Card>
    );
  })}
  </CardsContainer>
}

const CardsContainer = styled.div`
  width: clamp(280px, 80%, 1200px);
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 0 auto;
`

const Card = styled.div`
height: auto;
padding: 20px;
color: var(--black);
width: 49%;
box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
transition: transform 250ms ease-in;
background-color: white;;
:hover {
  transform: translateY(-2px);
}
.title {
  font-weight: 700;
}
.snippet {

}

`;

const TableView = ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <Table>
      <thead style={{ marginBottom: 20 }}>
        <tr>
          <th>result</th>
          <th>term</th>
          <th>translation</th>
          <th>definition</th>
        </tr>
      </thead>

      <tbody>
        {data &&
          data.map((element, idx) => {
            return (
              <tr key={idx} className="ResultsContainerTR">
                <td>{idx + 1}</td>
                <td>{element.title}</td>
                <td>{element.snippet}</td>
                <td className="ResultsContainerTD">{element.definition}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const SearchContainer = styled.div`
  margin: 0 auto;
  margin-top: 30vh;
  margin-bottom: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 10px;
  h1 {
    font-size: 2.8rem;
    margin-bottom: 20px;
  }
`;

const Underline = styled.span`
  border-bottom: 5px var(--primaryColor) solid;
`