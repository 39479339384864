import React, { useContext } from "react";
import styled from "styled-components";
import Search from "./components/Search";
import NavBar from "./components/nav/NavBar";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import LoginAdmin from "./components/login/LoginAdmin";
import { AdminContext } from "./contexts/AdminContext";
import AddDef from "./components/admin/AddDefinition/AddDef";
import Definitions from "./components/admin/Definitions/Definitions";
import { Button } from "./styles/styles";
import "./styles.css";

function App() {
  const { isLoggedin } = useContext(AdminContext);
  const location = useLocation();
  return (
    <Main>
      {isLoggedin && location.pathname === "/" && (
        <Link to="/admin">
          <Button type="button">Admin</Button>
        </Link>
      )}
      <Routes>
        <Route path="/" element={<Search />} />
        {isLoggedin && (
          <Route path="/admin" element={<NavBar />}>
            {isLoggedin && <Route path="add-def" element={<AddDef />} />}
            {isLoggedin && <Route path="show-def" element={<Definitions />} />}
          </Route>
        )}
        {!isLoggedin && <Route path="/login" element={<LoginAdmin />} />}
      </Routes>
    </Main>
  );
}

export default App;

const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
