import React, { useState } from "react";
import { Botonera, Button, Form } from "../../../styles/styles";
import useEditQuery from "../../../helpers/useEditQuery";
import styled from "styled-components";

function FormEditDefinition({ data, setEditDefinition }) {
  const [editedDef, setEditedDef] = useState(data);
  const Mutation = useEditQuery();

  return (
    <EditForm
      onSubmit={(e) => {
        e.preventDefault();
        Mutation.mutate(editedDef);
        setEditDefinition({ edit: false, id: null });
      }}
      flexDirection="row"
    >
      <label>Title:</label>
      <input
        type="text"
        value={editedDef.title}
        onChange={(e) => setEditedDef({ ...editedDef, title: e.target.value })}
      />
      <label>Snippet:</label>
      <input
        type="text"
        value={editedDef.snippet}
        onChange={(e) =>
          setEditedDef({ ...editedDef, snippet: e.target.value })
        }
      />
      <label>Video Link:</label>
      <input
        type="text"
        value={editedDef.video_link}
        onChange={(e) =>
          setEditedDef({ ...editedDef, video_link: e.target.value })
        }
      />
      <label>language:</label>
      <input
        type="text"
        value={editedDef.language}
        onChange={(e) =>
          setEditedDef({ ...editedDef, language: e.target.value })
        }
      />
      <label>Definition:</label>
      <textarea
        type="text"
        value={editedDef.definition}
        onChange={(e) =>
          setEditedDef({ ...editedDef, definition: e.target.value })
        }
      />
      <Botonera>
        <Button type="submit"> Submit </Button>
        <Button
          onClick={() => setEditDefinition({ edit: false, id: null })}
          close
          window
        >
          X
        </Button>
      </Botonera>
    </EditForm>
  );
}

export default FormEditDefinition;

const EditForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 450px;
  label {
    font-size: 0.8rem;
  }
  input {
    font-size: 1rem;
    max-width: 450px;
  }
  textarea {
    max-width: 450px;
  }
`;
