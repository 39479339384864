import { useMutation, useQueryClient } from "react-query";
import { baseURL } from "./baseUrl";

function useAddQuery() {
  const queryClient = useQueryClient();

  return useMutation(
    async (def) =>
      await fetch(`${baseURL}adminarea/add-definition`, {
        mode: "cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(def),
      }).then((res) => {
        if (res.ok) {
          return res;
        } else {
          throw new Error("error saving data");
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("definitions"),
    }
  );
}

export default useAddQuery;
