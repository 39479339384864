import React, { useContext } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import { AdminContext } from "../../contexts/AdminContext";
import styled from "styled-components";
import { Button, UnderlineButton } from "../../styles/styles";
import { useMediaQuery } from "react-responsive";

const NavBar = () => {
  const { adminLogout } = useContext(AdminContext);
  const location = useLocation();
  const handleLogout = () => {
    adminLogout();
  };
  const match = useMediaQuery({ query: "(min-width: 600px)" });

  return (
    <>
      <Nav>
        <Link to="/">
          <Button type="button">Home</Button>
        </Link>
        {location.pathname !== "/" &&
          <MenuRight>
            <Button onClick={() => handleLogout()}>Logout</Button>
            <Link to="add-def">
              <UnderlineButton>Add</UnderlineButton>
            </Link>
            <Link to="show-def">
              <UnderlineButton>Show Definitions</UnderlineButton>
            </Link>
          </MenuRight>
        }
      </Nav>
      {!match && <ScreenMidWidth>Suggested screen min-width: 600px</ScreenMidWidth>}
      <Outlet />
    </>
  );
};

export default NavBar;

const ScreenMidWidth = styled.p`
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  font-family: monospace;
  text-decoration: underline;
`

const MenuRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-right: 16px;
`;

const Nav = styled.nav`
  position: fixed;
  right: 0;
  top: 16px;
  width: 100%;
  background-color: var(--bg-dark);
  display: flex;
  justify-content: space-between;
  a {
    font-size: 1.2rem;
  }
  ul {
    margin-left: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    li {
      margin-right: 20px;
    }
    a {
      background-color: var(--primaryColor);
      border-radius: 2px;
      padding: 5px 10px;
      border: 2px var(--primaryColor) solid;
      &:hover {
        border-color: white;
        color: white;
      }

    }
  }
`;
