import { useMutation, useQueryClient } from "react-query";
import { baseURL } from "./baseUrl";

export default function useEditQuery(definition) {
  const queryClient = useQueryClient();
  return useMutation(
    async (definition) =>
      await fetch(`${baseURL}adminarea/edit`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(definition),
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(),
    }
  );
}
