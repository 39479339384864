import { useState } from "react";
import styled, { css } from "styled-components";

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalState = {
    isOpen,
    close: () => setIsOpen(false),
    open: () => setIsOpen(true),
  };

  const Modal = ({ children, isOpen }) => {
    return <StyledModal isOpen={isOpen}>{children}</StyledModal>;
  };

  return [modalState, Modal];
};

const StyledModal = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.9);
  ${(props) =>
    props.isOpen
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 20;
        `
      : "display:none"}
`;
