import React, { createContext, useState } from "react";
import { baseURL } from "../helpers/baseUrl";
import { useNavigate } from "react-router";

export const AdminContext = createContext();

const AdminContextProvider = (props) => {
  const [isLoggedin, setAdminLogin] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const tryToLogin = async (accessData) => {
    try {
      const parsedAccessData = JSON.stringify(accessData);
      const result = await fetch(`${baseURL}adminarea/login`, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: parsedAccessData,
      });
      if (result.status === 200) {
        setAdminLogin(true);
        navigate("/admin");
      } else {
        throw new Error("There was a problem");
      }
    } catch (error) {
      setAdminLogin(false);
      setIsError(true);
      setTimeout(() => setIsError(false), 4000);
    }
  };

  const adminLogout = () => {
    setAdminLogin(false);
    navigate("/");
  };

  const reset = () => {
    setIsError(false);
  };

  return (
    <AdminContext.Provider
      value={{
        tryToLogin,
        isLoggedin,
        adminLogout,
        isError,
        reset,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
