import { useQuery } from "react-query";
import { baseURL } from "./baseUrl";

function useQueryOne(id) {
  return useQuery(
    ["definition", id],
    async () =>
      await fetch(`${baseURL}search/${id}`).then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 404) {
          return;
        } else {
          throw new Error("error fetching definition");
        }
      })
  );
}

export default useQueryOne;
