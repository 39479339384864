import React, { useState, useContext, useEffect } from "react";
import { AdminContext } from "../../contexts/AdminContext";
import styled from "styled-components";
import { Button, Form } from "../../styles/styles";
import { useModal } from "../utils/useModal";
import { Link } from "react-router-dom";

const LoginAdmin = () => {
  const [Login, setLogin] = useState({ email: "", password: "" });
  const { tryToLogin, isError, reset } = useContext(AdminContext);
  const [modal, Modal] = useModal();

  const styleForm = {
    display: "flex",
    flexDirection: "column",
  };

  useEffect(() => {
    if (isError) {
      modal.open();
    } else {
      modal.close();
    }
  }, [isError, modal]);

  return (
    <LoginContainer active={modal.isOpen}>
      <ArrowBack to="/">&#11013;</ArrowBack>
      <Form
        style={styleForm}
        onSubmit={(e) => {
          e.preventDefault();
          tryToLogin(Login);
          setLogin({ email: "", password: "" });
        }}
      >
        <label>user ID</label>
        <input
          type="text"
          required
          value={Login.email}
          onChange={(e) => setLogin({ ...Login, email: e.target.value })}
        />
        <label>Password</label>
        <input
          type="password"
          required
          value={Login.password}
          onChange={(e) => setLogin({ ...Login, password: e.target.value })}
        />
        <Button type="submit">Login</Button>
      </Form>
      {modal.isOpen && (
        <Modal isOpen={modal.isOpen}>
          <h2>Something went wrong, please try again later.</h2>
          <Button type="button" onClick={() => reset()}>
            Close
          </Button>
        </Modal>
      )}
    </LoginContainer>
  );
};

export default LoginAdmin;

const LoginContainer = styled.div`
  padding-top: 20vh;
  ${(props) => props.active & "pointer-events:none"}
`;

const ArrowBack = styled(Link)`
  color: var(--primaryColor);
  font-size: 2rem;
  top: 10px;
  left: 20px;
  position: fixed;
  border: 3px white solid;
  padding: 2px 10px;
  border-radius: 50%;
  cursor: pointer;
`;
